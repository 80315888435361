import styles from './../vacancy.module.scss';
import addIcon from '../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../assets/icons/trash.svg';
import infoIcon from '../../../../assets/icons/info_icon.svg';
import Select, { SelectSortType } from '../../../components/inputs/Select';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { validateLanguageRequest } from '../../candidates/components/languages/languages';
import { selectEmtpy } from '../../../core/hardcode';

export const VacancyLanguages = ({
    appContext,
    vacancy,
    setVacancy,
    vacancyCache,
    vacancyErrors,
    setVacancyErrors,
    languagesSelecteds,
    setLanguagesSelecteds,
    catLanguages,
    catLevels,
    setStep
}) => {

    const handleOnClickAdd = () => {
        const { languages } = vacancy;
        const errorValidations = validateLanguageRequest(languages);
        setVacancyErrors(errorValidations);
        const errors = Object.keys(errorValidations);
        const errorLang = errors.filter( w => w.includes('language_')).length > 0;
        const errorLevel = errors.filter( w => w.includes('langLevel_')).length > 0;
        if ( !(errorLang || errorLevel) && languages.length < catLanguages.length ) {
            const index = languages.length;
            const levelBasic = catLevels.find( c => c.id === 1 );
            const tmpLanguage = {
                id: 0,
                languageId: 0,
                langLevelId: 1,
                language: selectEmtpy,
                'index': index,
                'langLevel': { id: levelBasic.id, description: levelBasic.optionName }
            }
            setVacancy({
                ...vacancy,
                languages: [...languages, tmpLanguage]
            });
        }
    }

    const handleOnClickRemove = index => {
        setLanguagesSelecteds([...languagesSelecteds.slice(0, index), ...languagesSelecteds.slice(index + 1)])
        const { languages } = vacancy;
        const languagesTmp = [ ...languages ];
        languagesTmp.splice(index, 1);
        setVacancy({
            ...vacancy,
            languages: languagesTmp
        });
        setVacancyErrors(validateLanguageRequest(languagesTmp));
    }

    const updateLanguage = (index, property, value, preValue) => {
        const indexReal = index - 1;
        const propertyReal = property.replace(`_${indexReal}`, '');
        const { languages } = vacancy;
        const language = {
            ...languages.find( l => l.index === indexReal ),
            [propertyReal]: value && value.id ? value : { id: 0, description: value.description },
            [`${propertyReal}Id`]: value.id ? value.id : 0
        }
        if( propertyReal === 'language' && value.id !== preValue.id ) {
            const langInCache = vacancyCache.languages.find(l => l.languageId === value.id);
            if(langInCache) {
                language.id = langInCache.id;
            } else {
                delete language.id;
            }
        }
        if (propertyReal === 'language' && value && value.id && value.id !== 0) {
            const optSelectedTmp = languagesSelecteds.filter( o => o !== preValue.id );
            setLanguagesSelecteds([...optSelectedTmp, value.id])
        }
        let languagesTmp = [ ...languages ];
        languagesTmp[indexReal] = language;
        setVacancy({
            ...vacancy,
            languages: languagesTmp
        });
        setVacancyErrors(validateLanguageRequest(languagesTmp));
    }

    const removedFromIcon = (id) => {
        const indexHelper = languagesSelecteds.indexOf(id)
        setLanguagesSelecteds([...languagesSelecteds.slice(0, indexHelper), ...languagesSelecteds.slice(indexHelper + 1)])
    }

    const renderTitles = index => vacancy.languages && vacancy.languages.length > 0 && (
        <div className={ styles.columns_line }>
            <div className={ `${styles.column} ${styles.widh45perc}` }>
                <span>{ `${appContext.t('candidate.resume.lang.labelLang')} ${index+1}*`}</span>
            </div>
            <div className={ styles.columns_line_2 }>
                <span>{ index === 0 ? `${appContext.t('candidate.resume.lang.labelLevel')}*` : ''}</span>
                { index === 0 && (<img src={ infoIcon } alt="" width="24" height="24" onClick={ () => setStep(6) } />) }
            </div>
        </div>
    )

    const renderLanguages = () => vacancy.languages && vacancy.languages.length > 0 && vacancy.languages.map((la, index) => (
        <div key={index}>
            { renderTitles( index) }
            <div className={ styles.columns_line }>
                <div className={ `${styles.column} ${styles.widh45perc}` }>
                    <Select
                        name={`language_${index}`}
                        placeholder={ appContext.t('candidate.resume.lang.labelLangPh') }
                        value={ la.language }
                        sercheable
                        onChange={ updateLanguage } 
                        options={ catLanguages ? catLanguages : [] }
                        optionsSelecteds={ languagesSelecteds }
                        removedFromIcon={ removedFromIcon }
                        index={ index+1 }
                        error= { Object.keys(vacancyErrors).includes(`language_${index}`) ? vacancyErrors[`language_${index}`][0] : '' }
                        sort={ SelectSortType.LANG }
                    />
                    {
                        Object.keys(vacancyErrors).includes(`language_${index}`) && (
                            <p className={ styles.error_message_font }>{ vacancyErrors[`language_${index}`][0] }</p>
                        )
                    }
                </div>
                <div className={ `${styles.column} ${styles.widh45perc}` }>
                    <div className={ styles.form_column_line }>
                        <Select 
                            name={`langLevel_${index}`}
                            placeholder={ appContext.t('candidate.resume.lang.labelLevelPh') }
                            value={ la.langLevel }
                            onChange={ updateLanguage } 
                            options={ catLevels ? catLevels : [] }
                            index={ index+1 }
                            error= { Object.keys(vacancyErrors).includes(`langLevel_${index}`) ? vacancyErrors[`langLevel_${index}`][0] : '' }
                        />
                        <div className={ styles.action_container }>
                            <img className={ styles.trash } src={ trashIcon } alt="" width={ 24 } height={ 24 } onClick={ () => handleOnClickRemove(index) } />
                        </div>
                    </div>
                    {
                        Object.keys(vacancyErrors).includes(`langLevel_${index}`) && (
                            <p className={ styles.error_message_font }>{ vacancyErrors[`langLevel_${index}`][0] }</p>
                        )
                    }
                </div>
            </div>
            
        </div>
    ))

    return (
        <div className={ styles.main }>
            <div className={ `${styles.card} ${styles.gap40}` }>
                <span className={ `${styles.purple_title} ${styles.purple_title_28px}` }>{ appContext.t('candidate.resume.lang.title') }</span>
                <span>{ appContext.t('vacancy.lang.instruction').replace(':vacancyName', defaultStringEmpty(vacancy.vacancyProfileName)) }</span>
                { renderLanguages() }
                <button className={ styles.add_info } onClick={() => handleOnClickAdd()}>
                    { appContext.t('candidate.resume.lang.labelAdd') }
                    <img src={ addIcon } alt="" />
                </button>
            </div>
        </div>
    )
}