import styles from './../vacancy.module.scss';
import addIcon from '../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../assets/icons/trash.svg';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { InputSearcher } from '../../candidates/components/skills/InputSearcher';
import { objEquals } from '../../../core/helper';
import { getOptionsSelecteds } from '../../candidates/components/skills/skills';
import { selectEmtpy } from '../../../core/hardcode';

export const VacancyBenefits = ({
    appContext,
    vacancy,
    setVacancy,
    vacancyErrors,
    setVacancyErrors,
    catBenefits=[]
}) => {

    const validateBenefits = (firstFocus) => {
        const errorDictHelper = {}
        for (const benefitIndex in vacancy.benefits) {
            const benefit = vacancy.benefits[benefitIndex];
            if (benefit.id) {
                continue
            }
            const id = `benefit_${benefitIndex}`;
            errorDictHelper[id] = appContext.t('validator.required');
            if( firstFocus ) {
                document.getElementById(id).focus();
                firstFocus = false;
            }
        }
        return errorDictHelper;
    }

    const onClickAdd = () => {
        // se valida
        const validationHelper = validateBenefits(true);
        if (Object.keys(validationHelper).length === 0) {
            const benefitNew = {
                id: 0,
                tagId: 0,
                name: '',
                description: '',
                tag: selectEmtpy
            }
            const benefitsCacheTmp = vacancy && vacancy.benefits && vacancy.benefits.length > 0 ? [ ...vacancy.benefits ] : [];
            setVacancy({ ...vacancy, benefits: [ ...benefitsCacheTmp, benefitNew] });
        }
        setVacancyErrors(validationHelper);
    }

    const updateBenefit = (tagId, categoryId, value, isSelected) => {
        let benefitsCacheTmp = [ ...vacancy.benefits ];
        const index = vacancy.benefits.findIndex(s => s.tagId === tagId);
        const benefit = {
            ...benefitsCacheTmp[index],
            tag: value,
            tagId: isSelected ? value.id : tagId,
            id: value.id,
            name: value.description
        }
        benefitsCacheTmp[index] = benefit;
        setVacancy({ ...vacancy, benefits: benefitsCacheTmp});
    }

    const handleOnClickRemove = benefit => {
        let benefitsCacheTmp = [ ...vacancy.benefits ];
        benefitsCacheTmp = vacancy.benefits.filter( s => !objEquals(s.tag, benefit.tag));
        setVacancy({ ...vacancy, benefits: benefitsCacheTmp});
    }

    const renderDeleter = (benefit, index) => (
        <div className={ styles.action_container }>
            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(benefit, index) } />
        </div>
    )

    const renderBenefit = () => vacancy && vacancy.benefits.length > 0 && (
        <div className={ `${styles.row} ${styles.no_gap}` }>
        {
            vacancy.benefits.map((benefit, index) => (
                <div key={ `benefit_${index}` }>
                    <div className={ `${styles.row} ${styles.padded_short}` }>
                        <div className={ styles.flex_14gap }>
                            <InputSearcher
                                id = {`benefit_${index}`}
                                tagId = { benefit.tagId }
                                value = { benefit.tag }
                                placeholder = { appContext.t('vacancy.benefits.labelSearch') }
                                onChange = { updateBenefit }
                                options = { catBenefits }
                                optionsSelecteds = { getOptionsSelecteds(vacancy.benefits) }
                                error = { Object.keys(vacancyErrors).includes(`benefit_${index}`) ? vacancyErrors[`benefit_${index}`] : undefined }
                                index={ index }
                                categoryId = { index }
                                width={ 45 }
                            />
                            { renderDeleter(benefit, index) }
                        </div>
                    </div>
                    <div className={ styles.row }>
                        {Object.keys(vacancyErrors).includes(`benefit_${index}`) && (
                            <p className={ styles.error_message } style={{ width: '50%' }}>{ vacancyErrors[`benefit_${index}`] }</p>
                        )}
                    </div>
                </div>
            ))
        }
        </div>
    );

    const renderComponent = () => {
        return (
            <div className={ styles.wrapper }>
                { renderBenefit() }
                <div className={ `${styles.row} ${ vacancy.benefits.length === 0 ? styles.top_padded : styles.top_padded_short}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd() }>
                        { `${appContext.t('button.add')} ${appContext.t('vacancy.benefits.single')}` }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        )
    }

    const renderBenefitsSection = () => (
        <div className={ styles.list }>
            { renderComponent() }
        </div>
    )

    return (
        <div className={ styles.main }>
            <div className={ `${styles.card} ${styles.gap40}` }>
                <span className={ `${styles.purple_title} ${styles.purple_title_28px}` }>{ appContext.t('vacancy.benefits.title') }</span>
                <span>{ appContext.t('vacancy.benefits.instruction').replace(':vacancyName', defaultStringEmpty(vacancy.vacancyProfileName)) }</span>
                { renderBenefitsSection() }
            </div>
        </div>
    )
}