import styles from './../vacancy.module.scss';
import stylesM from '../../../components/modal/modal_base.module.scss';
import { InputText } from '../../../components/inputs/InputText';
import Select, { SelectSortType } from '../../../components/inputs/Select';
import { TextArea } from '../../../components/inputs/TextArea';
import { selectEmtpy } from '../../../core/hardcode';
import { validateNotNull } from '../../../core/validators';

const vacancyValidationFields = {
    vacancyProfileName: [validateNotNull],

}

export const validateVacancy = vacancy => {
    let firstFocus = true;
    const vacancyErrors = {};
    var helper;
    for(const field in vacancyValidationFields) {
        for (const validator in vacancyValidationFields[field]) {
            helper = vacancyValidationFields[field][validator](vacancy[field])
            if (helper) {
                vacancyErrors[field] = helper;
                if( firstFocus ) {
                    document.getElementsByName(field)[0].focus();
                    firstFocus = false;
                }
            }
        }
    }
    return vacancyErrors;
}

export const VacancyDescription = ({
    appContext,
    vacancy,
    vacancyErrors,
    catArea,
    catRecruiter,
    catAddress,
    onChangeInput,
    onChangeSelect
}) => {

    const renderSection = (title, child, isMain) => (
        <div className={ `${styles.card} ${isMain ? styles.gap40 : styles.gap32}` }>
            <div className={ styles.header }>
                <div className={ styles.container }>
                    { !isMain && <div className={ styles.orange_bar } /> }
                    <span className={ `${styles.purple_title} ${isMain ? styles.purple_title_28px : styles.purple_title_20px}` }>{ appContext.t(title) }</span>
                </div>
            </div>
            { child }
        </div>
    )

    const renderMainSection = () => (
        <div className={ `${styles.column} ${styles.gap40}` }>
            <div className={ styles.row }>
                <InputText
                    cssContainer={ `${styles.column} ${styles.column_item}` }
                    name={ 'vacancyProfileName' }
                    label={ `${appContext.t('vacancy.info.vacancyName')}*` }
                    value={ vacancy.vacancyProfileName ? vacancy.vacancyProfileName : '' }
                    onChange={ onChangeInput }
                    error={ Object.keys(vacancyErrors).includes("vacancyProfileName") ? vacancyErrors.vacancyProfileName[0] : '' }
                    marginError={ Object.keys(vacancyErrors).includes("vacancyProfileName") && !Object.keys(vacancyErrors).includes("vacancyProfileName") }
                />
                <div className={ `${styles.column} ${styles.column_item}` }>
                    <div className={ stylesM.custom_select }>
                        <label>{ appContext.t('candidate.resume.assignment.labelArea') }</label>
                        <Select
                            name="area"
                            index={ 1 }
                            placeholder={ appContext.t('vacancy.info.placeholderArea') }
                            value={ vacancy.area ? vacancy.area : selectEmtpy }
                            sercheable
                            onChange={ onChangeSelect } 
                            options={ catArea }
                            error={ Object.keys(vacancyErrors).includes("area") ? vacancyErrors.area[0] : '' }
                            sort={ SelectSortType.ALPHA }
                        />
                        {
                            Object.keys(vacancyErrors).includes("area") && (
                                <p className={ styles.error_message_font }>
                                    { vacancyErrors.area ? vacancyErrors.area[0] : '' }
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={ styles.row }>
                <div className={ `${styles.column} ${styles.column_item}` }>
                    <div className={ stylesM.custom_select }>
                        <label>{ appContext.t('vacancy.info.recruiter') }</label>
                        <Select
                            name="recruiter"
                            index={ 2 }
                            placeholder={ appContext.t('vacancy.info.placeholderRecruiter') }
                            value={ vacancy.recruiter ? vacancy.recruiter : selectEmtpy }
                            sercheable
                            onChange={ onChangeSelect } 
                            options={ catRecruiter }
                            error={ Object.keys(vacancyErrors).includes("recruiter") ? vacancyErrors.recruiter[0] : '' }
                            sort={ SelectSortType.ALPHA }
                        />
                        {
                            Object.keys(vacancyErrors).includes("recruiter") && (
                                <p className={ styles.error_message_font }>
                                    { vacancyErrors.recruiter ? vacancyErrors.recruiter[0] : '' }
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )

    const renderModality = () => (
        <div className={ `${styles.column} ${styles.gap32}` }>
            <div className={ styles.row }>
                <div className={ `${styles.column} ${styles.column_item} ${styles.column_item_full}` }>
                    <div className={ stylesM.custom_select }>
                        <label>{ appContext.t('vacancy.info.modality.address') }</label>
                        <Select
                            name="address"
                            index={ 2 }
                            placeholder={ appContext.t('vacancy.info.modality.placeholderAddress') }
                            value={ vacancy.address ? vacancy.address : selectEmtpy }
                            sercheable
                            onChange={ onChangeSelect } 
                            options={ catAddress }
                            error={ Object.keys(vacancyErrors).includes("address") ? vacancyErrors.address[0] : '' }
                            sort={ SelectSortType.ALPHA }
                        />
                        {
                            Object.keys(vacancyErrors).includes("address") && (
                                <p className={ styles.error_message_font }>
                                    { vacancyErrors.address ? vacancyErrors.address[0] : '' }
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={ `${styles.column} ${styles.column_item} ${styles.column_item_full}` }>
                <TextArea
                    cssCounter={ styles.subtitle_left }
                    name={ 'horary' }
                    label={ appContext.t('vacancy.info.modality.horary') }
                    maxLength={ 200 }
                    errorLen={ 'validator.length' }
                    value={ vacancy.horary || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(vacancyErrors).includes("horary") ? vacancyErrors.horary[0] : '' }
                />
            </div>
            <div className={ `${styles.column} ${styles.column_item} ${styles.column_item_full}` }>
                <TextArea
                    cssCounter={ styles.subtitle_left }
                    name={ 'modality' }
                    label={ appContext.t('vacancy.info.modality.detail') }
                    maxLength={ 200 }
                    errorLen={ 'validator.length' }
                    value={ vacancy.modality || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(vacancyErrors).includes("modality") ? vacancyErrors.modality[0] : '' }
                />
            </div>
        </div>
    )

    const renderEducation = () => (
        <div className={ `${styles.column} ${styles.gap32}` }>
            <div className={ `${styles.column} ${styles.column_item} ${styles.column_item_full}` }>
                <TextArea
                    cssCounter={ styles.subtitle_left }
                    name={ 'experience' }
                    label={ appContext.t('vacancy.info.history.experience') }
                    maxLength={ 200 }
                    errorLen={ 'validator.length' }
                    value={ vacancy.experience || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(vacancyErrors).includes("experience") ? vacancyErrors.experience[0] : '' }
                />
            </div>
            <div className={ `${styles.column} ${styles.column_item} ${styles.column_item_full}` }>
                <TextArea
                    cssCounter={ styles.subtitle_left }
                    name={ 'education' }
                    label={ appContext.t('vacancy.info.history.education') }
                    maxLength={ 200 }
                    errorLen={ 'validator.length' }
                    value={ vacancy.education || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(vacancyErrors).includes("education") ? vacancyErrors.education[0] : '' }
                />
            </div>
        </div>
    )

    return (
        <div className={ styles.main }>
            { renderSection('vacancy.info.title', renderMainSection(), true) }
            { renderSection('vacancy.info.modality.title', renderModality()) }
            { renderSection('vacancy.info.history.title', renderEducation()) }
        </div>
    )
}