import { useEffect, useState } from "react";
import styles from './vacancy.module.scss';
import stylesHeader from '../../components/header/header.module.scss';
import { VacancyResume } from "./components/VacancyResume"
import { VacancyDescription, validateVacancy } from "./components/VacancyDescription";
import { VacancyResponsabilities } from "./components/VacancyResponsabilities";
import { VacancySkills } from "./components/VacancySkills";
import { VacancyLanguages } from "./components/VacancyLanguages";
import { VacancyBenefits } from "./components/VacancyBenefits";
import { defaultStringEmpty } from "../../core/talent-module/helper";
import { objEquals, setLanguageSelecValues, setSkillSelecValue } from "../../core/helper";
import closeIcon from '../../../assets/icons/close.svg';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import { getCatalog, getLangLevelCatalog, getSkillsCandidateCatalog } from "../../api/catalogClient";
import { LanguagesLevel } from "../candidates/components/languages/level_description";
import { getVacancy } from "../../api/vacancyClient";
import { sorterAlphAsc } from "../catalogs/profiles/ProfileList";

// Hardcode remove when the services will be complete
const languages = [
    {
        "langLevelId": 2,
        "langLevelName": "A2",
        "langLevelDescription": "Básico:Soy capaz de comprender y comunicarme con frases y expresiones sencillas de uso cotidiano y personal.",
        "languageId": 3,
        "languageName": "Portugués",
        "languageDescription": "Portugués",
        "flagUrl": null,
        "localeIdentifier": null
    },
    {
        "langLevelId": 5,
        "langLevelName": "C1",
        "langLevelDescription": "Avanzado:Puedo expresarme de forma fluida y espontánea, y comprender y redactar una amplia variedad de textos extensos y complejos relacionados con mi área de experiencia.",
        "languageId": 4,
        "languageName": "Italiano",
        "languageDescription": "Italiano",
        "flagUrl": null,
        "localeIdentifier": null
    },
    {
        "langLevelId": 3,
        "langLevelName": "B1",
        "langLevelDescription": "Intermedio:Se utilizará para escribir textos simples y comprender textos claros en inglés, así como leer y entender correos electrónicos simples, informes breves y comunicaciones internas relacionadas con\r\n tu trabajo.",
        "languageId": 1,
        "languageName": "Inglés",
        "languageDescription": "Inglés",
        "flagUrl": null,
        "localeIdentifier": null
    }
]

const skills = [
    {
        "id": 5378,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true,
        "candidatesOnly": true,
        "category": {
            "id": 1001100001,
            "valueItem": "Competencias técnicas",
            "description": "Competencias técnicas",
            "catalogId": 1000010011
        }
    },
    {
        "id": 5379,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true,
        "candidatesOnly": true,
        "category": {
            "id": 1001100001,
            "valueItem": "Competencias técnicas",
            "description": "Competencias técnicas",
            "catalogId": 1000010011
        }
    },
    {
        "id": 5380,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true,
        "candidatesOnly": true,
        "category": {
            "id": 1001100002,
            "valueItem": "Competencias personales",
            "description": "Competencias personales",
            "catalogId": 1000010011
        }
    },
    {
        "id": 5381,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true,
        "candidatesOnly": true,
        "category": {
            "id": 1001100002,
            "valueItem": "Competencias personales",
            "description": "Competencias personales",
            "catalogId": 1000010011
        }
    },
    {
        "id": 5382,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true,
        "candidatesOnly": true,
        "category": {
            "id": 1001100004,
            "valueItem": "Software",
            "description": "Software",
            "catalogId": 1000010011
        }
    }
]

const benefits = [
    {
        "id": 5378,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true
    },
    {
        "id": 5379,
        "isPublic": true,
        "createdById": 91,
        "isEnable": true
    }
]

export const Vacancy = ({
    appContext,
    vacancyIn,
    catStatus,
}) => {

    const [step, setStep] = useState(0);
    const [vacancy, setVacancy] = useState(vacancyIn);
    const [vacancyCache, setVacancyCache] = useState(vacancyIn);
    const [vacancyErrors, setVacancyErrors] = useState(false);
    const [catArea, setCatArea] = useState([]);
    const [catRecruiter, setCatRecruiter] = useState([]);
    const [catAddress, setCatAddress] = useState([]);
    const [catSkills, setCatSkills] = useState([]);
    const [catLanguages, setCatLanguages] = useState([]);
    const [catLevels, setCatLevels] = useState();
    const [languagesSelecteds, setLanguagesSelecteds] = useState([]);

    const loadCatSkills = ({status, data}) => {
        const catTmp = [];
        if( status === 200 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map( r => {
                catTmp.push({ id: r.id ,optionName: r.name, categoryId: r.categoryId, percentage: r.skillLevelPercentage});
            })
            setCatSkills(catTmp);
        }
        return catTmp;
    }

    const loadCatTranslate = ({status, data}) => {
        const languagesTmp = [];
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map( l => {
                languagesTmp.push({ id: Number(l.valueItem), optionName: l.description });
            })
            setCatLanguages(languagesTmp);
        }
        return languagesTmp;
    }

    const loadCatLangLevels = ({status, data}) => {
        const langLevelsTmp = [];
        if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
            appContext.showLoading(false, '', styles.no_scroll);
            data.result.map( l => {
                let desc = l.description ? l.description : '';
                desc = desc.substring(0, desc.indexOf(':'));
                desc = `${l.name}${desc ? ' - ' : ''}${desc}`;
                const language = { id: l.id, optionName: desc}
                langLevelsTmp.push(language);
            })
            setCatLevels(langLevelsTmp);
        }
        return langLevelsTmp;
    }

    const loadLanguages = (catLanguages, catLevels) => {
        const languagesTmp = [];
        const languagesSelected = [];
        languages.forEach((language, index) => {
            const laWithSelecValues = setLanguageSelecValues(language, index, catLanguages, catLevels);
            languagesTmp.push(laWithSelecValues);
            languagesSelected.push(language.languageId);
        })
        setLanguagesSelecteds(languagesSelected)
        return languagesTmp;
    }

    const loadList = (list, cat) => {
        let listTmp = [];
        list.map((item, index) => {
            const itemWithTagValue = setSkillSelecValue(item, index, cat, true);
            itemWithTagValue.tagId = item.id;
            listTmp.push(itemWithTagValue);
        })
        listTmp = listTmp.sort( (a, b) => sorterAlphAsc(a,b) );
        return listTmp;
    }

    const listWorkModality = vacancy => [
        { label: appContext.t('vacancy.info.modality.address'), value: defaultStringEmpty('Puerta Polanco: Blvd. Manuel Ávila Camacho No. 5, Col. Lomas de Sotelo, Naucalpan de Juárez, Parque Toreo.') },
        { label: appContext.t('vacancy.info.modality.horary'), value: defaultStringEmpty('Tiempo completo - Lunes a Viernes de 9:00 a 18:00.') },
        { label: appContext.t('vacancy.info.modality.detail'), value: defaultStringEmpty('Híbrida - Deberás contar con disponibilidad para asistir a sitio de acuerdo a las necesidades del proyecto.') },
    ]

    const listHistory = vacancy => [
        { label: appContext.t('vacancy.info.history.experience'), value: defaultStringEmpty('3-4 años de experiencia en puesto similar.') },
        { label: appContext.t('vacancy.info.history.education'), value: defaultStringEmpty('Ing. en Sistemas, Científico de Datos, Lic. en Matemáticas, o afín.') }
    ]

    const loadVacancy = catalogs => {
        getVacancy(appContext.userData.token, vacancy.id).then(({status, data}) => {
            // console.log(status, data);
            vacancy.modalities = listWorkModality(data);
            vacancy.history = listHistory(data);
            vacancy.responsabilities = [];
            vacancy.responsability = '';
            vacancy.skills = loadList(skills, catalogs[0]);
            vacancy.languages = loadLanguages(catalogs[1], catalogs[2]);
            vacancy.benefits = loadList(benefits, catalogs[0]);
            setVacancyCache(vacancy);
            appContext.showLoading(false, '', styles.no_scroll);
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log('Error loading vacancy', error);
        })
    }

    const loadCatalogs = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        const requests = [
            getSkillsCandidateCatalog(0, 10000, appContext.userData.token),
            getCatalog(1000020054, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token),
            getLangLevelCatalog(appContext.userData.token)
        ]
        const functions = [
            loadCatSkills,
            loadCatTranslate,
            loadCatLangLevels,
        ]
        Promise.all(requests).then( responses => {
            const catalogs = [];
            responses.forEach((response, index) => catalogs.push(functions[index](response)))
            if(catalogs.length === functions.length) {
                loadVacancy(catalogs);
            }
        }).catch( error => {
            console.log('Error loading catalogs', error);
            appContext.showLoading(false, '', styles.no_scroll);
        });
    }

    useEffect(() => {
        loadCatalogs();
    }, [vacancyIn])

    const updateView = (index, navLabel) => {
            let menuNavigation = [ ...appContext.menuNavigation ];
            if( !index ) {
                appContext.setMenuNavigation([...appContext.menuNavigation, { label: `${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`}]);
            } else {
                menuNavigation.push({
                    label: defaultStringEmpty(`${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`), 
                    onEvent: setStep,
                    arg: '0'
                });
                menuNavigation.push({
                    label: navLabel,
                    onEvent: setStep,
                    arg: index
                });
                appContext.setMenuNavigation(menuNavigation);
            }
            appContext.contentScrollUp();
            setStep(index);
        }

    const onChangeInput = (property, value) => {
        setVacancy({ ...vacancy, [property]: value});
    }

    const onChangeSelect = (index, property, value) => {
        setVacancy({
            ...vacancy,
            [property]: value && value.id ? value : { id: 0, description: value.description },
            [`${property}Id`]: value && value.id ? value.id : 0,
            [`${property}Value`]: value && value.description ? value.description : ''
        });
    }

    const renderBackButton = (title, stepValue) => step === 6 && (
        <div className={ styles.backbutton } onClick = { () => setStep(4) }>
            <img src={ backArrowIcon } alt="" /><span>{ appContext.t('button.back') }</span>
        </div>
    )

    const formSections = [
        <VacancyResume
            appContext={appContext}
            updateView={ updateView }
            vacancy={vacancy}
            catStatus={catStatus}
        />,
        <VacancyDescription
            appContext={appContext}
            vacancy={vacancy}
            vacancyErrors={vacancyErrors}
            catArea={catArea}
            catRecruiter={catRecruiter}
            catAddress={catAddress}
            onChangeInput={onChangeInput}
            onChangeSelect={onChangeSelect}
        />,
        <VacancyResponsabilities
            appContext={appContext}
            vacancy={vacancy}
            vacancyErrors={vacancyErrors}
            onChangeInput={onChangeInput}
        />,
        <VacancySkills
            appContext={appContext}
            vacancy={vacancy}
            setVacancy={setVacancy}
            vacancyErrors={vacancyErrors}
            setVacancyErrors={setVacancyErrors}
            catSkills={catSkills}
        />,
        <VacancyLanguages
            appContext={appContext}
            vacancy={vacancy}
            setVacancy={setVacancy}
            vacancyCache={vacancyCache}
            vacancyErrors={vacancyErrors}
            setVacancyErrors={setVacancyErrors}
            languagesSelecteds={languagesSelecteds}
            setLanguagesSelecteds={setLanguagesSelecteds}
            catLanguages={catLanguages}
            catLevels={catLevels}
            setStep={setStep}
        />,
        <VacancyBenefits
            appContext={appContext}
            vacancy={vacancy}
            setVacancy={setVacancy}
            vacancyErrors={vacancyErrors}
            setVacancyErrors={setVacancyErrors}
            catBenefits={catSkills}
        />,
        <LanguagesLevel classNameTitle={ `${styles.purple_title} ${styles.purple_title_28px}` } renderBackButton={renderBackButton} />,
    ]

    const onModalDiscard = () => {
        setVacancy(vacancyCache)
        onCancel();
    }

    const modalChangesBase = (title, child) => (
        <div className={ stylesHeader.error_modal }>
            <div className={ stylesHeader.wrapper }>
                <div className={ stylesHeader.content_title }>
                    <p className={ stylesHeader.title }>{ title }</p>
                    <img src={ closeIcon } alt='' onClick={ () => appContext.setModalFlag(false) } />
                </div>
                { child }
                <div className={ stylesHeader.content_buttons }>
                    <div className={ stylesHeader.buttons }>
                        <button className={ stylesHeader.cancel } onClick={onModalDiscard}>{ appContext.t('checkUp.modalCancel.cancel') }</button>
                        <button className={ stylesHeader.confirm } onClick={() => onSave()}>{ appContext.t('button.save') }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderModalChanges = () => modalChangesBase(appContext.t('candidate.modal.change.title'),
        <span><b>{ appContext.t('candidate.modal.change.msg1') } { appContext.t('vacancy.modal.change.msg') }.</b> { appContext.t('candidate.modal.change.msg2') }</span>
    )

    const onCancel = () => {
        appContext.setModalFlag(false);
        appContext.contentScrollUp();
        updateView(0, `${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`);
    }

    const saveSuccess = () => {
        appContext.displayNotification((appContext.t('update')));
        updateView(0, `${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`);
    }

    const onSave = () => {
        appContext.setModalFlag(false);
        setVacancyErrors({});
        let validationErrors = {}
        if ( step === 1 ) {
            validationErrors = validateVacancy(vacancy);
            if (Object.keys(validationErrors).length === 0) {
                setVacancyCache(vacancy);
                saveSuccess();
            } else {
                setVacancyErrors(validationErrors);
            }
        }
    }

    const onValidateBack = () => {
        if(!objEquals(vacancy, vacancyCache)) {
            appContext.setModalFlag(!appContext.modalFlag);
            appContext.setModalContent(renderModalChanges());
        } else {
            onCancel()
        }
    }

    const renderButtons = () => (
        <div className={ `${styles.buttons_container} ${styles.paddingEnd}` }>
            <div className={ styles.buttons }>
                <button className={ `${styles.button} ${styles.button_cancel}` } onClick={ onValidateBack }>{ appContext.t('button.cancel') }</button>
                <button className={ `${styles.button} ${styles.button_save}` } onClick={() => onSave()}>{ appContext.t('button.save') }</button>
            </div>
        </div>
    )

    return (
        <div className={ styles.vacancy}>
            { appContext.isDesktopOrLaptop && renderBackButton( null, step === 6 ? 4 : null) }
            { formSections[step] }
            { step > 0 && <hr className={ styles.margin_long } /> }
            { step > 0 && step !== 6 && renderButtons() }
        </div>
    );
}
