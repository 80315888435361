import moment from "moment";
import 'moment/locale/es';
import { MONTHS, selectEmtpy, yearsOrderDesc } from "./hardcode";
import i18next from "../../i18n";

export const serializeDateString = (month, year) => {
    const monthStr = month < 10 ? `0${month}` : month;
    return `${year}-${monthStr}-01`
}

export const unsearializaDateString = (dateStr) => {
    const dateArray = dateStr.split('-');
    const year = dateArray[0];
    const month = Number(dateArray[1]);
    const day = dateArray[2];
    return {
        'day': day,
        monthId: month,
        yearDesc: year
    }
}

export const serializeSelectValue = ({ id, optionName }) => ({
    id: id,
    description: optionName
})

export const objEquals = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const sorterDate = (yearId1, monthId1, yearId2, monthId2, order) => {
    let result = 0;
    const yearPos = order === yearsOrderDesc ? yearId1 > yearId2 : yearId1 < yearId2;
    const yearMNeg = order === yearsOrderDesc ? yearId1 < yearId2 : yearId1 > yearId2;
    if (yearPos) {
        result = 1
    } else if (yearMNeg) {
        result = -1
    }
    if( result === 0 ) {
        if (monthId1 < monthId2) {
            result = 1
        } else if (monthId1 > monthId2) {
            result = -1
        }
    }
    // console.log('sorter date', yearId1, monthId1, yearId2, monthId2, result);
    return result;
}

export const borderError = error => error ? { borderColor: 'var(--color-error-ligth)' } : {}
export const borderErrorCheck = error => !error ? {} : {
    border: '1px solid var(--color-error-ligth)',
    borderRadius: '5px'
}

export const setStartAndEndDates = (obj, rangeYear1, rangeYear2) => {
    const element = { ...obj };
    if ( element.startDate ) {
        const startDate = unsearializaDateString(element.startDate);
        const fiMes = MONTHS.find( m => m.id === startDate.monthId )
        const fiAnio = rangeYear1.find( y => y.optionName === startDate.yearDesc)
        element.fiMes = serializeSelectValue(fiMes);
        element.fiAnio = serializeSelectValue(fiAnio);
    } else {
        element.fiMes = selectEmtpy;
        element.fiAnio = selectEmtpy;
    }
    if ( element.endDate ) {
        const endDate = unsearializaDateString(element.endDate);
        const ftMes = MONTHS.find( m => m.id === endDate.monthId )
        const ftAnio = rangeYear2.find( y => y.optionName === endDate.yearDesc)
        element.ftMes = serializeSelectValue(ftMes);
        element.ftAnio = serializeSelectValue(ftAnio);
        element.stillWork = false;
    } else {
        element.ftMes = selectEmtpy;
        element.ftAnio = selectEmtpy;
        element.stillWork = true;
    }
    return element;
}

export const setEndAndValidityDates = (obj, rangeYear1, rangeYear2) => {
    const element = { ...obj };

    if ( element.startDate ) {
        const startDate = unsearializaDateString(element.startDate);
        const fiMes = MONTHS.find( m => m.id === startDate.monthId )
        const fiAnio = rangeYear1.find( y => y.optionName === startDate.yearDesc)
        element.fiMes = serializeSelectValue(fiMes);
        element.fiAnio = serializeSelectValue(fiAnio);
    } else {
        element.fiMes = selectEmtpy;
        element.fiAnio = selectEmtpy;
    }

    if (element.endDate) {
        const endDate = unsearializaDateString(element.endDate);
        const ftMes = MONTHS.find( m => m.id === endDate.monthId )
        try {
            const ftAnio = rangeYear1.find( y => y.optionName === endDate.yearDesc)
            element.ftMes = serializeSelectValue(ftMes);
            element.ftAnio = serializeSelectValue(ftAnio);
        } catch {
            element.endDate = null
            element.ftMes = selectEmtpy;
            element.ftAnio = selectEmtpy;
        }
    } else {
        element.ftMes = selectEmtpy;
        element.ftAnio = selectEmtpy;
    }

    if ( element.validityDate ) {
        const validityDate = unsearializaDateString(element.validityDate);
        const fvMes = MONTHS.find( m => m.id === validityDate.monthId )
        const fvAnio = rangeYear2.find( y => y.optionName === validityDate.yearDesc)
        element.fvMes = serializeSelectValue(fvMes);
        element.fvAnio = serializeSelectValue(fvAnio);
        element.validity = false;
    } else {
        element.fvMes = selectEmtpy;
        element.fvAnio = selectEmtpy;
        element.validity = true;
    }
    element.stillStudy = (!element.endDate ? true : false)
    return element;
}

export const setLanguageSelecValues = (obj, index, catLanguage, catLangLevel) => {
    const element = { ...obj };
    element.id = index+1;
    element.index = index;
    const language = catLanguage.find( l => l.id === obj.languageId )
    const level = catLangLevel.find( l => l.id === obj.langLevelId);
    element.language = serializeSelectValue(language ? language : { id: obj.languageId, optionName: obj.languageName } );
    element.langLevel = serializeSelectValue(level ? level : { id: obj.langLevelId, optionName: obj.langLevelDescription });
    return element;
}

export const setSkillSelecValue = (obj, index, catSkills, isProfile=false) => {
    const element = { ...obj };
    let tag = null;
    if( isProfile ) {
        tag = catSkills.find( l => l.id === obj.id )
        element.index = index;
        if( obj.category ) {
            element.categoryId = obj.category.id;
        }
        element.name = tag ? tag.optionName : '';
        element.tag = tag ? { ...serializeSelectValue(tag) } : {
            id: element.id,
            description: element.name
        };
    } else {
        tag = catSkills.find( l => l.id === obj.tagId )
        element.id = element.tagId ? element.tagId : element.id;
        element.index = index;
        element.tag = tag ? { ...serializeSelectValue(tag) } : {
            id: element.tagId ? element.tagId : element.id,
            description: element.name
        };
    }
    return element;
}

export const orderWorkExperiences = workExperiences => 
    workExperiences.sort((a, b) => sorterDate(
        a.stillWork ? a.fiAnio.id : a.ftAnio.id, 
        a.stillWork ? a.fiMes.id : a.ftMes.id, 
        a.stillWork ? b.fiAnio.id : b.ftAnio.id, 
        a.stillWork ? b.fiMes.id : b.ftMes.id,
        yearsOrderDesc
    ));

export const isNum = value => {
    const expression = /^\d+$/;
    const regex = new RegExp(expression);
    const result = regex.test(value);
    return result;
}

export const onClickPreviewCV = id => {
    const link = document.createElement('a');
    link.href = `/pdf-preview/${id}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
}

export const removeAccents = s => {
    return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const buildString = (listOfString, strDefault) => {
    if( Array.isArray(listOfString) ) {
        let lString = '';
        listOfString.forEach((s, index) => lString = `${lString}${ index > 0 ? ', ' : ''}${s}`)
        return lString === '' ? strDefault : lString;
    }
    return listOfString === '' ? strDefault : listOfString;
}

export const buildDateString = (date, wordConnector, secondConnector) => {
    if( date ) {
        moment.locale('es');
        return `${moment(date).format('DD')} ${wordConnector ? wordConnector : ''} ${moment(date).format('MMMM')} ${secondConnector ? wordConnector + ' ' : ''}${moment(date).format('YYYY')}`
    }
    return i18next.t('noData.unspec');
}

export const buildCurrencyFormat = value => {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3}).format(value,);
}

export const stringToFormatDate = value => {
    const diagonal = '/';
    let newValue = value.replaceAll(diagonal, '');
    const valueSize = newValue.length;
    if( valueSize > 0 && valueSize <= 8 && /^\d+$/.test(newValue)) {
        if( valueSize > 2 && valueSize <= 4 ) {
            newValue = `${newValue.substring(0, 2)}${diagonal}${newValue.substring(2, valueSize)}`;
        } else if ( valueSize > 4 && valueSize <= 8 ) {
            newValue = `${newValue.substring(0, 2)}${diagonal}${newValue.substring(2, 4)}${diagonal}${newValue.substring(4, valueSize)}`;
        }
        return newValue;
    } else if ( valueSize > 8 ) {
        return value.substring(0, 10);
    } else if ( valueSize === 0 ) {
        return '';
    }
}