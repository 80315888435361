import PropTypes from 'prop-types';
import { useContext, useState } from "react";
import styles from './searcher.module.scss';
import dropDownIcon from '../../../../assets/icons/arrow_drop.svg'
import dropDownIcon2 from '../../../../assets/icons/arrow_down.svg';
import closeIcon from '../../../../assets/icons/close_small.svg';
import { objEquals } from '../../../core/helper';
import { AppContext } from '../../../pages/candidates/context/AppProvider';

export const Select = ({
	name,
	value,
	setValue,
	options,
	placeholder,
	noSearcheable,
	onChange,
	optRigth,
	fontbold,
}) => {
	const appContext = useContext(AppContext);
	const [showOptions, setShowOptions] = useState(false);

    const onSelectOption = o => {
		const newValue = options.find( v => objEquals(v,o) );
		appContext.setCurrentPage(0);
		setValue(newValue);
		setShowOptions(false);
		if( onChange ) {
			onChange(newValue.id);
		}
    }

	const cleanOptions = () => {
		appContext.setCurrentPage(0);
		setValue(null);
	}

	const handleOnBlur = () => setTimeout(() => setShowOptions(false), 100);

    const renderOptions = () => (
		<div className={ styles.container_select_options } style={ optRigth ? { right: '0', top: '48px' } : { left: '0', top: '55px' }}>
			{
				options.map((o, index) => (
					<div key={index} onClick={ () => onSelectOption(o) } className={ styles.container_select_options_option }>
						{ o.optionName }
					</div>
				))
			}
		</div>
	);

	return (
		<div tabIndex="1"
			className={ `${styles.container_select} ${value ? styles.container_selected : undefined} ${value && noSearcheable ? styles.container_selected_secondary : undefined}` }
			onClick={ () => setShowOptions(!showOptions) }
			onBlur={ handleOnBlur }>
			<div className={ styles.container_select_label }>
				<div className={ styles.container_select_label }>
					{ value && !noSearcheable && <img className={ styles.container_selected_close } src={ closeIcon } alt="" onClick = { cleanOptions } /> }
					<span className={ `${ fontbold ? styles.font_bold : undefined }` }>{ value ? `${ !noSearcheable ? placeholder + ': ' : '' }${value.optionName}` : placeholder }</span>
				</div>
				<img className={ `${value ? styles.container_selected_arrow : undefined} ${noSearcheable ? styles.container_selected_secondary_arrow : undefined} ${ showOptions ? styles.container_selected_rotate : undefined }` } src={ noSearcheable ? dropDownIcon2 : dropDownIcon } alt="" />
			</div>
			{ showOptions && renderOptions() }
		</div>
	);
};

Select.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.shape({
		id: PropTypes.number.isRequired,
		optionName: PropTypes.string.isRequired,
	}),
	setValue: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			optionName: PropTypes.string.isRequired,
		}),
	).isRequired,
	noSearcheable: PropTypes.bool,
	onChange: PropTypes.func,
	optRigth: PropTypes.bool
};