import {
    useState,
    useEffect,
    useContext } from 'react';

import styles from './candidateInfo.module.scss';
import notificationStyles from '../../components/notification/notification.module.scss';

import CandidateForms from './candidateForms';
import CandidateResume from './candidateResume';
import admirationIcon from '../../../assets/icons/admiration_white.svg';
import { AppContext } from './context/AppProvider';
import { candidateFormsDesfase, selectEmtpy } from '../../core/hardcode';
import { getCatalog, getClientCatalog, getLangLevelCatalog, getSkillsCandidateCatalog } from '../../api/catalogClient';
import { getSkillProfiles } from '../../api/skillClient';
import { getAllUsers } from '../../api/userClient';

export const LoadedFrom = {
    SKILLS: 'catalog_skill',
    PROFILES: 'catalog_profile',
    VACANCIES: "catalog_vacancy"
}

const CandidateInfo = ({
    tab = 0,
    rvf = false,
    from,
    resumeTab=0,
    feedback,
    showReport
}) => {
    const appContext = useContext(AppContext);

    const [personalInfo, setPersonalInfo] = useState({ country: selectEmtpy, countryName: '' })
    const [stepIndex, setStepIndex] = useState(tab);
    const [resumeViewFlag, setResumeViewFlag] = useState(rvf);
    const [catLevels, setCatLevels] = useState();
    const [countries, setCountries] = useState([]);
    const [catType, setCatType] = useState([]);
    const [catTranslate, setCatTranslate] = useState([]);
    const [catCurrencies, setCatCurrencies] = useState([]);
    const [catSeniority, setCatSeniority] = useState([]);
    const [catClient, setCatClient] = useState([]);
    const [catLeader, setCatLeader] = useState([]);
    const [catViewer, setCatViewer] = useState([]);
    const [catSkills, setCatSkills] = useState([]);
    const [catSkillProfile, setCatSkillProfile] = useState([]);

    const loadCatTranslate = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        getCatalog(1000020054, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token).then(({status, data}) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                let languagesTmp = [];
                data.result.content.map( l => {
                    languagesTmp.push({ id: Number(l.valueItem), optionName: l.description });
                })
                setCatTranslate(languagesTmp);
            }
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
    }

    const loadCatLangLevels = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        getLangLevelCatalog(appContext.userData.token).then((response) => {
            if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
                appContext.showLoading(false, '', styles.no_scroll);
                let langLevelsTmp = [];
                response.data.result.map( l => {
                    let desc = l.description ? l.description : '';
                    desc = desc.substring(0, desc.indexOf(':'));
                    desc = `${l.name}${desc ? ' - ' : ''}${desc}`;
                    const language = { id: l.id, optionName: desc}
                    langLevelsTmp.push(language);
                })
                setCatLevels(langLevelsTmp);
            }
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
    }

    const loadCatalog = (catalogId, order, setCatalog, optDesc = false) => {
        getCatalog(catalogId, 0 ,200, order, appContext.userData.token).then(({status, data}) => {
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                let catTmp = [];
                data.result.content.map( l => {
                    const type = { id: l.catalogItemId, optionName: optDesc ? l.description : l.valueItem, description: optDesc ? l.valueItem : l.description }
                    catTmp.push(type);
                })
                setCatalog(catTmp);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const loadCatClient = () => {
        getClientCatalog(appContext.userData.token).then( response => {
          if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
            let clientsTmp = [];
            response.data.result.map( l => clientsTmp.push({ id: l.id, optionName: l.name}))
            setCatClient(clientsTmp);
        }
        }).catch( error => {
          console.log(error);
        })
    }

    const loadCatUsers = (roleId, setCatUser) => {
        getAllUsers(appContext.userData.token, null, roleId, null).then( response => {
            if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
                appContext.showLoading(false, '', styles.no_scroll);
                let usersTmp = [];
                response.data.result.content.map( u => {
                    const user = { id: u.id, optionName: `${u.firstName} ${u.lastName}`}
                    usersTmp.push(user);
                })
                setCatUser(usersTmp);
            }
        }).catch( error => {
            console.log(error);
        })
    }

    const loadCatSkills = () => {
        getSkillsCandidateCatalog(0, 10000, appContext.userData.token).then(({status, data}) => {
            if( status === 200 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                let catTmp = [];
                data.result.content.map( r => {
                    catTmp.push({ id: r.id ,optionName: r.name, categoryId: r.categoryId});
                })
                setCatSkills(catTmp);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const loadCatSkillProfile = () => {
        getSkillProfiles(appContext.userData.token).then( response => {
            if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
              let skillProfileTmp = [];
              response.data.result.map( sp => skillProfileTmp.push({ id: sp.id, optionName: sp.name}))
              setCatSkillProfile(skillProfileTmp);
          }
          }).catch( error => {
            console.log(error);
          })
    }

    const displayNotification = (message, isWarn, timer, isErrorConection) => {
        appContext.contentScrollUp();
        const style = isErrorConection ? notificationStyles.notification_wrapper_error : (isWarn ? notificationStyles.notification_wrapper_warning : notificationStyles.notification_wrapper);
        const icon = isWarn ? admirationIcon : undefined;
        appContext.setNotificationData({
            notificationStyle: ( `${ style } ${notificationStyles.active}` ),
            message: message,
            icon: icon,
            isDisplay: true,
            isErrorConection: isErrorConection
        });

        if( !isErrorConection && timer > 0 ) {
            setTimeout(() => {
                appContext.setNotificationData({
                    notificationStyle: style,
                    message: message,
                    icon: icon,
                    isDisplay: false
                })
            }, timer);
        }
    }

    const candidateForm = <CandidateForms
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        displayNotification={ displayNotification }
        resumeViewFlag={ resumeViewFlag }
        personalInfo={ personalInfo }
        setPersonalInfo={ setPersonalInfo }
        appContext={ appContext }
        catLevels={ catLevels }
        countries={ countries }
        catType={ catType }
        catTranslate={ catTranslate }
        catCurrencies={ catCurrencies }
        catSeniority={ catSeniority }
        catClient={ catClient }
        catLeader={ catLeader }
        catViewer={ catViewer }
        catSkills={ catSkills }
        loadCatSkills={ loadCatSkills }
        catSkillProfile={ catSkillProfile }
        from={ from }
    />;

    useEffect(() => {
        loadCatLangLevels();
        loadCatalog(1000010011, '&sort=valueItem&direction=ASC', setCatType);
        loadCatalog(1000020016, '&sort=id&direction=ASC', setCountries);
        loadCatalog(1000020055, '&sort=id&direction=ASC', setCatCurrencies, true);
        loadCatalog(1000030003, '&sort=id&direction=ASC', setCatSeniority);
        loadCatTranslate();
        loadCatClient();
        loadCatUsers(1000000010, setCatLeader);
        loadCatUsers(1000000009, setCatViewer);
        loadCatSkills();
        loadCatSkillProfile();
        // cargando datos solo si hay talent id
        if( !tab ) {
            setStepIndex(appContext.talent.id ? 8 : 0)
        }
    }, [])

    return(
        <div className={ `${appContext.showMenu ? styles.main : styles.main_full}` } >
            <div
                className={ styles.candidate_info }
                style={{ background: stepIndex === 9 ? 'linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%)' : 'white'  }}
            >
                <div className={ styles.wrapper }>
                    { (stepIndex <= 7 || stepIndex >= candidateFormsDesfase) && candidateForm }
                    {
                        stepIndex === 8 && (
                            <CandidateResume
                                setStepIndex={setStepIndex}
                                setResumeViewFlag={ setResumeViewFlag }
                                personalInfo={ personalInfo }
                                displayNotification={ displayNotification }
                                catClient={ catClient }
                                catUser={ catLeader }
                                catCurrencies={ catCurrencies }
                                catSkills={ catSkills }
                                catSkillProfile={ catSkillProfile }
                                catType={ catType }
                                resumeTab={ resumeTab }
                                feedback={ feedback }
                                showReport={ showReport }
                            />)
                    }
                </div>
            </div>
        </div>
    )
}

export default CandidateInfo