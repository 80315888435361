import { useState, useEffect } from 'react';
import styles from '../candidates/candidateInfo.module.scss';
import { getCatalog } from '../../api/catalogClient';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import { objEquals } from '../../core/helper';
import { Climate, validateClimate } from './components/climate/Climate';
import { FeedbackComments, validateFeedbackComments } from './components/comments/FeedbackComments';
import { ImprovementActions, validateImprovActions } from './components/improvement/ImprovementActions';
import { InternalNotes, validateInternalNotes } from './components/notes/InternalNotes';
import { CheckupResume } from './components/CheckupResume';
import { executeMultiPromises } from './thunks/loadThunks';
import {
    isGoToImproveSection,
    onSaveClimate,
    onSaveComments,
    onSaveImprovementActions,
    onSaveInternalNotes
} from './thunks/saveThunks';

export const Checkup = ({
    appContext,
    review,
    setReview,
    reviews,
    setReviews,
    setChild,
    catType,
    isFirstReport,
    setIsFirstReport
}) => {
    const [tap, setTap] = useState(0);
    const [checkup, setCheckup] = useState();
    const [checkupCache, setCheckupCache] = useState();
    const [checkupErrors, setCheckupErrors] = useState(false);
    const [catClasification, setCatClasification] = useState();
    const [catExistRisk, setCatExistRisk] = useState();
    const [catLevelSatisfaction, setCatLevelSatisfaction] = useState();
    const [modeUpdate, setModeUpdate] = useState(false);
    const [modeRead, setModeRead] = useState(true);
    const [hasFollowItems, setHasFollowItems] = useState();

    const validateCheckupRequest = () => {
        if( tap === 0 && checkup.followUps ) {
            return validateClimate(tap, checkup, hasFollowItems);
        } else if( tap === 1 ) {
            return validateClimate(tap, checkup);
        } else if( tap === 3 ) {
            return validateFeedbackComments(checkup);
        } else if( tap === 4 ) {
            return validateImprovActions(checkup);
        } else if( tap === 5 ) {
            return validateInternalNotes(checkup);
        }
        return {};
    }

    const onChangeInput = ( property, value ) => {
        setCheckup({ ...checkup, [property]: value })
    }

    const changeTap = (newTap, updateInReviews) => {
        // const realNewTap = modeUpdate && tap !== 6 && tap !== 0 && (tap <= 2 && newTap === 0) ? 6 : newTap;
        const realNewTap = modeUpdate && tap !== 6 && tap !== 2 ? 6 : newTap;
        setTap(realNewTap)
        if( !modeUpdate && updateInReviews ) {
            setReview({ ...review, 'stageIndex': realNewTap });
            const reviewsAux = [ ...reviews ];
            const reviewIndex = reviews.findIndex( r => r.id === review.id );
            reviewsAux[reviewIndex].stageIndex = realNewTap;
            setReviews(reviewsAux);
        }
        appContext.contentScrollUp();
    }

    const formSections = [
        <Climate
            appContext={appContext}
            tap={tap}
            checkup={checkup}
            checkupErrors={checkupErrors}
            changeTap={changeTap}
            onChangeInput={onChangeInput}
            catClasification={catClasification}
            catExistRisk={catExistRisk}
            catLevelSatisfaction={catLevelSatisfaction}
            modeResume={modeUpdate}
            hasFollowItems={hasFollowItems}
        />,
        null,
        null,
        <FeedbackComments
            appContext={appContext}
            review={review}
            checkup={checkup}
            checkupErrors={checkupErrors}
            onChangeInput={onChangeInput}
            catType={catType}
            modeResume={modeUpdate}
        />,
        <ImprovementActions
            appContext={appContext}
            checkup={checkup}
            checkupErrors={checkupErrors}
            onChangeInput={onChangeInput}
            modeResume={modeUpdate}
        />,
        <InternalNotes
            appContext={appContext}
            checkup={checkup}
            checkupErrors={checkupErrors}
            onChangeInput={onChangeInput}
            modeResume={modeUpdate}
        />,
        <CheckupResume
            appContext={appContext}
            tap={tap}
            changeTap={changeTap}
            review={review}
            setReview={setReview}
            reviews={reviews}
            setReviews={setReviews}
            checkup={checkup}
            checkupErrors={checkupErrors}
            catClasification={catClasification}
            catExistRisk={catExistRisk}
            catLevelSatisfaction={catLevelSatisfaction}
            onChangeInput={onChangeInput}
            modeRead={modeRead}
            setModeRead={setModeRead}
        />
    ]

    const titles = ['climate', 'climate', 'climate', 'comments', 'improvement', 'notes', 'report']

    useEffect(() => {
        if( review && review.processStatus && review.processStatus.id > 1000530001 ) {
            setModeUpdate(true);
            changeTap(6);
        } else if (isFirstReport) {
            changeTap(1);
        } else if (review.stageIndex) {
            changeTap(review.stageIndex);
        }
        const sorter = '&sort=id&direction=ASC';
        const requestCatalogs = [
            getCatalog(1000030058, 0, 200, sorter, appContext.userData.token),
            getCatalog(1000030056, 0, 200, sorter, appContext.userData.token),
            getCatalog(1000030057, 0, 200, sorter, appContext.userData.token),
            getCatalog(1000020059, 0, 200, sorter, appContext.userData.token)
        ]
        const functions = [
            setCatClasification,
            setCatExistRisk,
            setCatLevelSatisfaction,
            null
        ]
        const context = {
            "requestArray": requestCatalogs,
            functions,
            appContext,
            setCheckup,
            setCheckupCache,
            "style": styles.no_scroll,
            review,
            setHasFollowItems
        }
        executeMultiPromises(context);
    }, [review])

    useEffect(() => {
        updateNavBarTitle(tap);
    }, [tap])

    const updateNavBarTitle = tap => {
        let menuNavigation = [ ...appContext.menuNavigation ];
        menuNavigation.pop();
        menuNavigation.push({
            label: `${appContext.t('candidate.resume.review.title')} ${review.period.substring(0, review.period.lastIndexOf(' '))}`,
            onEvent: setChild,
            arg: null
        });
        menuNavigation.push({label: appContext.t(`checkUp.${titles[tap]}.title`)});
        appContext.setMenuNavigation(menuNavigation);
    }

    const onSave = modeBack => {
        appContext.setModalFlag(false);
        setModeRead(true);
        setCheckupErrors({});
        const checkupErrors = validateCheckupRequest();
        if (Object.keys(checkupErrors).length === 0) {
            const currentTap = tap;
            const context = {
                appContext,
                checkup,
                setCheckup,
                setCheckupCache,
                tap,
                changeTap,
                "style": styles.no_scroll,
                review,
                setReview,
                reviews,
                setReviews,
                setModeUpdate,
                modeUpdate,
                setHasFollowItems,
                modeBack
            }
            if( tap === 0 || tap === 1 ) {
                onSaveClimate(context);
            } else if ( tap === 3 ) {
                onSaveComments(context);
            } else if( tap === 4 ) {
                onSaveImprovementActions(context);
            } else if( tap === 5 ) {
                onSaveInternalNotes(context);
            } else if ( tap === 6 ) {

            }
            if( modeBack ) {
                if (currentTap === 1 && !hasFollowItems) {
                    setIsFirstReport(false);
                }
                onCancel(currentTap);
            }
        } else {
            setCheckupErrors(checkupErrors);
        }
    }

    const onCancel = inputTab => {
        const tapAux = inputTab ? inputTab : tap;
        appContext.setModalFlag(false);
        if( (checkup && !checkup.id && tapAux === 1) || (modeUpdate && tapAux === 6) || (!modeUpdate && tapAux === 0) || (!modeUpdate && tapAux === 1 && !hasFollowItems)) {
            setChild(null);
            let menuNavigation = [ ...appContext.menuNavigation ];
            menuNavigation.pop();
            menuNavigation.push({ label: `${appContext.t('candidate.resume.review.title')} ${review.period.substring(0, review.period.lastIndexOf(' '))}` });
            appContext.setMenuNavigation(menuNavigation);
        } else if( modeUpdate && (tapAux === 1 || (tapAux >= 3 && tapAux <= 5)) ) {
            changeTap(6);
        } else if (!modeUpdate && (tapAux === 3 || tapAux === 1)) {
            changeTap(tapAux === 3 && !hasFollowItems ? 1 : 0);
        } else if ( tapAux >= 4 ) {
            if (tapAux === 5) {
                const isThereNoOpportunities = !review.opportunities || review.opportunities.length === 0;
                const tapBackward = isThereNoOpportunities ? 3 : 4;
                changeTap(tapBackward);
            } else {
                changeTap(tapAux-1);
            }
        }
        setModeRead(true);
        appContext.contentScrollUp();
    }

    const onValidateBack = () => {
        if(!objEquals(checkup, JSON.parse(checkupCache))) {
            onSave(true);
        } else {
            onCancel()
        }
    }

    const renderButtons = () => (
        <div className={ styles.buttons_spaced }>
            <div className={ styles.buttons } style={{ padding: '0px' }}>
                <button className={ styles.cancel_button } onClick={ onValidateBack }>{ appContext.t( modeUpdate ? 'button.cancel' : 'back') }</button>
                <button className={ styles.continue_button } onClick={ () => onSave() }>{ appContext.t( modeUpdate ? 'checkUp.btnSaveChanges' : ( tap === 5 ? 'checkUp.btnFinish' : 'continue')) }</button>
            </div>
        </div>
    )

    const renderBackButton = () => (
        <div className={ styles.backbutton } onClick = { () => changeTap(0) }>
            <img src={ backArrowIcon } alt="" /><span>{ appContext.t('button.back') }</span>
        </div>
    )

    return checkup && (
        <div className={ styles.main } >
            <div className={ styles.candidate_info } >
                <div className={ styles.wrapper }>
                    <div className={ styles.candidate_resume }>
                        <div className={ styles.content_wrapper }>
                            <div className={ styles.candidate_columns }>
                                <div className={ styles.candidate_form } style={{ padding: '0px', width: '100%' }}>
                                    { tap === 2 && renderBackButton() }
                                    { formSections[tap >= 0 && tap <= 2 ? 0 : tap] }
                                    { tap !== 2 && tap < 6 && <hr className={ styles.margin_long } /> }
                                    { tap !== 2 && tap < 6 && renderButtons() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}