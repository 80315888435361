import { useContext, useEffect } from 'react';
import styles from './../vacancy.module.scss';
import addIcon from '../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../assets/icons/trash.svg';
import editIcon from '../../../../assets/icons/blue_pencil.svg';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId } from '../../../core/talent-module/hardcode';
import { InputSearcher } from '../../candidates/components/skills/InputSearcher';
import { CandidateContext } from '../../candidates/context';
import { objEquals } from '../../../core/helper';
import { checkSkillWithCompetition, getOptionsSelecteds } from '../../candidates/components/skills/skills';
import { newSkill, selectEmtpy } from '../../../core/hardcode';
import CandidateInfo, { LoadedFrom } from '../../candidates/candidateInfo';

export const VacancySkills = ({
    appContext,
    vacancy,
    setVacancy,
    vacancyErrors,
    setVacancyErrors,
    catSkills
}) => {

    const { skill, cleanSkill } = useContext( CandidateContext );
    const {
        competition,
        setCompetition,
        setCompetitionErrors
    } = useContext(CandidateContext);

    const validateSkills = (firstFocus, skillsCacheFiltred) => {
        const errorDictHelper = {}
        for (const skillObjectIndex in skillsCacheFiltred) {
            const skill = skillsCacheFiltred[skillObjectIndex];
            if (skill.id) {
                continue
            }
            const id = `${skillsCacheFiltred[skillObjectIndex].categoryId}_${skillObjectIndex}`;
            errorDictHelper[id] = appContext.t('validator.required');
            if( firstFocus ) {
                document.getElementById(id).focus();
                firstFocus = false;
            }
        }
        return {
            focus: firstFocus,
            errors: errorDictHelper
        };
    }

    const validateSkillRequest = (firstFocus, categoryId = null) => {
        let errorDictHelper = {}
        if ( categoryId ) {
            const skillsCacheFiltred = vacancy.skills ? vacancy.skills.filter(skill => skill.categoryId === categoryId) : [];
            errorDictHelper = validateSkills(firstFocus, skillsCacheFiltred).errors;
        } else {
            const skillCatalogIds = [hardSkillsCatalogId,softSkillsCatalogId,softwareCatalogId];
            let focus = firstFocus;
            skillCatalogIds.forEach( cs => {
                const skillsCacheFiltred = vacancy.skills ? vacancy.skills.filter(skill => skill.categoryId === cs) : [];
                const result = validateSkills(focus, skillsCacheFiltred);
                focus = result.focus;
                errorDictHelper = { ...errorDictHelper, ...result.errors };
            })
        }
        return errorDictHelper;
    }

    const checkSkillFromCompModule = () => {
        if( competition ) {
            const index = vacancy.skills.findIndex(s => checkSkillWithCompetition(s, competition));
            if( index >= 0 ) {
                let skillsTmp = [ ...vacancy.skills ];
                skillsTmp[index].id = competition.tagId;
                skillsTmp[index].tagId = competition.tagId;
                skillsTmp[index].name = competition.tag.description;
                skillsTmp[index].tag = competition.tag;
                skillsTmp[index].categoryId = competition.categoryId;
                setVacancy({ ...vacancy, skills: skillsTmp });
                setCompetition(null);
            }
        }
    }

    useEffect(() => {
        checkSkillFromCompModule();
    }, [])

    const onClickAdd = categoryId => {
        // se valida
        const validationHelper = validateSkillRequest(true, categoryId);
        if (Object.keys(validationHelper).length === 0) {
            const skillNew = { ...skill, 'categoryId': categoryId };
            cleanSkill(categoryId);
            const skillsCacheTmp = vacancy && vacancy.skills ? [ ...vacancy.skills ] : [];
            setVacancy({ ...vacancy, skills: [ ...skillsCacheTmp, skillNew] });
        }
        setVacancyErrors(validationHelper);
    }

    const updateSkill = (tagId, categoryId, value, isSelected) => {
        let skillsCacheTmp = [ ...vacancy.skills ];
        const index = vacancy.skills.findIndex( s => s.tagId === tagId && s.categoryId === categoryId );
        const skill = {
            ...skillsCacheTmp[index],
            tag: value,
            tagId: isSelected ? value.id : tagId,
            id: value.id,
            name: value.description
        }
        skillsCacheTmp[index] = skill;
        setVacancy({ ...vacancy, skills: skillsCacheTmp});
    }

    const onSkillEditPage = (value, categoryId) => {
        appContext.setTalent(null);
        appContext.contentScrollUp();
        const competition = {
            ...newSkill,
            id: value.id,
            name: value.description,
            category: { ...selectEmtpy, id: categoryId },
            categoryId
        };
        const title1 = appContext.t(`candidate.resume.skills.addForm.title${ competition.id ? 'Edit' : 'Create' }`);
        const title2 = categoryId !== softwareCatalogId ? appContext.t('candidate.resume.skills.addForm.titleSkill') : appContext.t('candidate.resume.soft.title');
        appContext.setMenuNavigation([...appContext.menuNavigation, { label: `${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`}, { label: `${title1} ${title2}` }]);
        setCompetition(competition);
        setCompetitionErrors({});
        appContext.setHomeContent(<CandidateInfo tab={ 18 } rvf from={ LoadedFrom.VACANCIES } />)
    }

    const handleOnClickRemove = skill => {
        let skillsCacheTmp = [ ...vacancy.skills ];
        skillsCacheTmp = vacancy.skills.filter( s => !objEquals(s.tag, skill.tag));
        setVacancy({ ...vacancy, skills: skillsCacheTmp});
    }

    const renderDeleter = (skill, categoryId, index) => (
        <div className={ styles.action_container }>
            { skill.id ? <img src={ editIcon } alt="" onClick={ () => onSkillEditPage(skill.tag, categoryId) } /> : undefined }
            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(skill, index) } />
        </div>
    )

    const renderSkills = (categoryId, skills) => skills && skills.length > 0 && (
        <div className={ `${styles.row} ${styles.no_gap}` }>
        {
            skills.map((skill, index) => (
                <div key={ `${categoryId}_${index}` }>
                    <div className={ `${styles.row} ${styles.padded_short}` }>
                        <div className={ styles.flex_14gap }>
                            <InputSearcher
                                id = {`${categoryId}_${index}`}
                                tagId = { skill.tagId }
                                value = { skill.tag }
                                placeholder = { appContext.t('candidate.resume.skills.labelSearch') }
                                onChange = { updateSkill }
                                options = { catSkills.filter( skill => skill.categoryId === categoryId ) }
                                optionsSelecteds = { getOptionsSelecteds(skills) }
                                error = { Object.keys(vacancyErrors).includes(`${categoryId}_${index}`) ? vacancyErrors[`${categoryId}_${index}`] : undefined }
                                index={ index }
                                categoryId = { categoryId }
                                onSkillEditPage = { onSkillEditPage }
                                width={ 45 }
                            />
                            { renderDeleter(skill, categoryId, index) }
                        </div>
                    </div>
                    <div className={ styles.row }>
                        {Object.keys(vacancyErrors).includes(`${categoryId}_${index}`) && (
                            <p className={ styles.error_message } style={{ width: '50%' }}>{ vacancyErrors[`${categoryId}_${index}`] }</p>
                        )}
                    </div>
                </div>
            ))
        }
        </div>
    );

    const renderComponentSkill = (categoryId, title) => {
        const skills = vacancy && vacancy.skills ? vacancy.skills.filter( skill => skill.categoryId === categoryId ) : [];
        return (
            <div className={ styles.wrapper }>
                <div className={ `${ skills.length > 0 ? styles.bottom_padded : undefined}` }>
                    <span>{ title }</span>
                </div>
                { renderSkills(categoryId, skills) }
                <div className={ `${styles.row} ${ skills.length === 0 ? styles.top_padded : styles.top_padded_short}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd(categoryId) }>
                        { `${appContext.t('button.add')} ${title}` }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        )
    }

    const renderSkillsSection = () => (
        <div className={ styles.list }>
            { renderComponentSkill(hardSkillsCatalogId, appContext.t('candidate.resume.skills.hard2')) }
            { renderComponentSkill(softSkillsCatalogId, appContext.t('candidate.resume.skills.soft2')) }
            { renderComponentSkill(softwareCatalogId, appContext.t('candidate.resume.soft.title')) }
        </div>
    )

    return (
        <div className={ styles.main }>
            <div className={ `${styles.card} ${styles.gap40}` }>
                <span className={ `${styles.purple_title} ${styles.purple_title_28px}` }>{ appContext.t('candidate.resume.skills.title') }</span>
                <span>{ appContext.t('vacancy.skills.instruction').replace(':vacancyName', defaultStringEmpty(vacancy.vacancyProfileName)) }</span>
                { renderSkillsSection() }
            </div>
        </div>
    )
}
